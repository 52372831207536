<template>
  <main>
    <div class="grid grid--center">
      <div class="grid__item grid__item--12 grid__item--m-10 grid__item--l-6 grid__item--spacing-2">
        <TextImage :text-image-data="loginFailed" />
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
import TextImage from '@/components/text-image.vue';

definePageMeta({
  layout: 'blank',
});

const loginFailed = {
  theme: 'dark',
  align: 'center',
  imgPosition: 'top',
  altText: 'Das T-Shop Hardware Tool ist nicht erreichbar',
  srcSmall: '/api/content/images/6b6e659e0167012699153cb014025ead/icon-404.png',
  content:
    '<h1>Keine Berechtigung</h1>          <p> Leider konnten wir Sie nicht einloggen - bitte versuchen Sie es erneut über den Link </p> <p><span>Hardware-Vermarktung &gt;</span></p> <p> in TVPP.  </p>',
};
</script>
